/** @format */

import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";
import { codeSlashOutline } from "ionicons/icons";

export const contentMiddle = (store) => (next) => (action) => {
	if (action.type === "content/loadContent") {
		ajax
			.get(
				store.getState().login.paths.api +
					store.getState().content.api.loadCourse +
					action.payload
			)
			.subscribe((result) => {
				switch (result.response.status) {
					case "OK":
						store.dispatch({
							type: "content/setModel",
							payload: result.response.result,
						});
						store.dispatch({
							type: "content/initModel",
						});
						store.dispatch({
							type: "content/setCourseStatus",
							payload: "INPROGRESS",
						});
						store.dispatch({
							type: "content/setStartTime",
						});
						store.dispatch({
							type: "login/setLauchCodeEntryState",
							payload: "LOADED",
						});
						break;
					default:
						console.log("NACK");
						break;
				}
			});
	}
	if (action.type === "content/setCurrentFrame") {
		if (
			action.payload < store.getState().content.registration.model.frames.length
		) {
			store.dispatch({
				type: "content/setCurrentFrameNum",
				payload: action.payload,
			});
			store.dispatch({
				type: "content/uploadCourse",
				payload: action.payload,
			});
		} else {
			console.log("End of course");
			store.dispatch({
				type: "content/setCourseStatus",
				payload: "FINISHED",
			});
			store.dispatch({
				type: "content/uploadCourse",
				payload: action.payload,
			});
		}
	}
	if (action.type === "content/uploadCourse") {
		try {
			ajax({
				url:
					store.getState().login.paths.api +
					store.getState().content.api.setRegistration,
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: store.getState().content.registration.model,
			}).subscribe();
		} catch (err) {
			console.log("An error occurred while saving to database.", err);
		}
	}

	if (action.type === "content/toggleAnswer") {
		let currentFrame = store.getState().content.registration.model.frames[
			store.getState().content.registration.model.meta.currentframe
		].assessment;
		console.log(
			currentFrame.answers[currentFrame.answers.type],
			currentFrame.meta.correctanswers
		);
		if (currentFrame.meta.correctanswers === 1) {
			store.dispatch({
				type: "content/deselectAllAnswers",
			});
		}
	}

	if (action.type === "content/setCourseEnd") {
		store.dispatch({ type: "login/resetLogin" });
	}

	return next(action);
};
