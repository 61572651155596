/** @format */

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import loginReducer from "./redux";
import courseReducer from "./courseReducer";
import stylesReducer from "./stylesReducer";
import contentReducer from "./contentReducer";
import frameReducer from "./frameReducer";
import { loginMiddle } from "./loginMiddle";
import { courseMiddle } from "./courseMiddle";
import { contentMiddle } from "./contentMiddle";
import { frameMiddle } from "./frameMiddle";

export default configureStore({
	reducer: {
		login: loginReducer,
		course: courseReducer,
		styles: stylesReducer,
		content: contentReducer,
		frame: frameReducer,
	},
	middleware: [
		...getDefaultMiddleware({ serializableCheck: false }),
		loginMiddle,
		courseMiddle,
		contentMiddle,
		frameMiddle,
	],
});
