/** @format */

import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

const ContentFrameVideo = (props) => {
	const [playing, setPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [progress, setProgress] = useState({});
	const [videoReady, setVideoReady] = useState(false);
	const videoPlayer = useRef(null);

	useEffect(() => {
		if (videoReady) {
			if (
				props.frameNumber === props.currentFrameIndex &&
				props.meta.status !== "FINISHED"
			) {
				if (progress && progress.played > 0) {
					props.mediaplaying(progress.played);
					if (progress.played === 1) {
						props.mediaplaying(0);
						props.setMediaState("IDLE");
					}
				} else {
					setPlaying(true);
				}
			}
		}
	}, [duration, progress, props, props.mediaplaying, videoReady]);

	/*
	const setProgress = (progress) => {
		console.log("progress", progress);
		//props.mediaplaying(progress);
	};*/
	return (
		<div>
			<ReactPlayer
				onReady={setVideoReady}
				playing={playing}
				ref={videoPlayer}
				url={props.video}
				controls={false}
				playsInline={true}
				onProgress={setProgress}
				onDuration={setDuration}
				progressInterval={500}
			/>
			{/* 
			<video
				controls
				width='100%'
				height='auto'
				style={{ width: "100%", height: "auto", display: "block" }}
				playsInline={true}
				preload='auto'
				autoPlay={false}>
				<source src={props.video} type='video/mp4' />
				Sorry, your browser doesn't support embedded videos.
			</video>*/}
		</div>
	);
};
export default ContentFrameVideo;
