/** @format */

import React, { useState, useEffect } from "react";
import ContentFrameImagetext from "./ContentFrameImagetext";
import ContentFrameVideo from "./ContentFrameVideo";
import ContentFrameAssessment from "./ContentFrameAssessment";
const Content = (props) => {
	const [playMedia, setPlayMedia] = useState(false);
	useEffect(() => {
		if (props.currentFrameIndex === props.frameNumber) {
			setPlayMedia(true);
		} else {
			setPlayMedia(false);
		}
	}, [props.currentFrameIndex, props.frameNumber]);

	useEffect(() => {
		if (props.replayIndex !== false) {
			if (props.currentFrameIndex === props.frameNumber) {
				setPlayMedia(true);
				props.setReplayIndex(false);
			}
		}
	}, [props, props.replayIndex]);

	useEffect(() => {
		switch (props.frame.frametype) {
			case "assessment":
				props.setMediaState("IDLE");
				break;
			default:
		}
	}, [props, props.frame.frametype]);

	return (
		<div>
			{{
				assessment: (
					<ContentFrameAssessment
						meta={props.meta}
						frame={props.frame}
						nextFrame={props.nextFrame}
					/>
				),
				video: (
					<ContentFrameVideo
						meta={props.meta}
						frame={props.frame}
						currentFrameIndex={props.currentFrameIndex}
						frameNumber={props.frameNumber}
						mediaState={props.mediaState}
						setMediaState={props.setMediaState}
						mediaplaying={(ev) => props.playing(ev)}
						video={
							props.mediaPrefix + props.frame.video.url + props.videoSuffix
						}
					/>
				),
			}[props.frame.frametype] || (
				<ContentFrameImagetext
					meta={props.meta}
					frameNumber={props.frameNumber}
					currentFrameIndex={props.currentFrameIndex}
					frame={props.frame}
					mediaState={props.mediaState}
					setMediaState={props.setMediaState}
					setPlayMedia={(ev) => setPlayMedia(ev)}
					mediaplaying={(ev) => props.playing(ev)}
					image={props.mediaPrefix + props.frame.image.url + props.imageSuffix}
					narration={
						props.mediaPrefix + props.frame.audio.url + props.narrationSuffix
					}
					style={props.style.frame}></ContentFrameImagetext>
			)}
		</div>
	);
};
export default Content;
