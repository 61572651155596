/** @format */

import React, { useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import LaunchCode from "../components/LaunchCode";
import LanguageSelector from "../components/LanguageSelector";
import ModeSelector from "../components/ModeSelector";
import { useSelector, useDispatch } from "react-redux";
import {
	appInit,
	login,
	resetLogin,
	setLauchCodeEntryState,
} from "../redux/redux";
import { courseReducer, removeCourse } from "../redux/courseReducer";
import { contentReducer } from "../redux/contentReducer";
import ReactJson from "react-json-view";
import Course from "../components/Course";
import Content from "../components/Content";
import logo from "../assets/viarspaceMetalSignMockup_04.png";
import loading from "../assets/loader3.gif";
import { stylesReducer } from "../redux/stylesReducer";
import rotatedevice from "../assets/rotatedevice.png";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import KeypadButton from "../components/KeypadButton";
const Main = () => {
	const loginState = useSelector(login);
	const stylesState = useSelector(stylesReducer);
	const courseState = useSelector(courseReducer);
	const contentState = useSelector(contentReducer);
	const dispatch = useDispatch();
	const setCourseFinish = () => {
		dispatch(resetLogin());
		dispatch(removeCourse());
	};
	useEffect(() => {
		dispatch(appInit());
	}, [dispatch]);
	const setAcknowledgeInvalidLaunchCode = () => {
		dispatch(setLauchCodeEntryState("IDLE"));
	};
	return (
		<IonPage>
			<IonContent>
				{contentState.registration.model ? (
					<Content></Content>
				) : (
					<div id='login' style={stylesState.login.container}>
						{/* 
							<LanguageSelector
							styles={stylesState.languages}
							languages={loginState.login.lang}></LanguageSelector>

						<ModeSelector
							mode={loginState.login.mode}
							language={loginState.login.lang.language}></ModeSelector>
*/}
						{
							{
								LAUNCHCODE: (
									<>
										{{
											LOADING: (
												<div style={stylesState.login.logoContainer}>
													<img
														style={{ height: "40vh" }}
														src={loading}
														alt='Please Wait'></img>
												</div>
											),
											EMPTY: (
												<div>
													<KeypadButton
														digit='Invalid Launch Code. Try Again'
														setButton={
															setAcknowledgeInvalidLaunchCode
														}></KeypadButton>
												</div>
											),
										}[loginState.login.mode.modes[0].status] || (
											<LaunchCode></LaunchCode>
										)}
									</>
								),
								PAIR: <>PAIR</>,
								LOGIN: <>LOGIN</>,
							}[loginState.login.mode.selected]
						}
						<div style={stylesState.login.logoContainer}>
							<div>
								<img
									src={logo}
									alt='Viarspace'
									style={stylesState.login.logo}></img>
							</div>
							<div style={{ color: "black" }}>
								<small>v0.9.9</small>
							</div>
						</div>
					</div>
				)}
			</IonContent>
		</IonPage>
	);
};

export default Main;
