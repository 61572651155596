/** @format */
import { Howl, Howler } from "howler";
const axios = require("axios");
var _ = require("lodash");

export const courseMiddle = (store) => (next) => (action) => {
	const courseState = store.getState().course;
	const loginState = store.getState().login;
	switch (action.type) {
		case "course/setNextCourseFrame":
			store.dispatch({
				type: "course/setFrameEndTime",
				payload: "",
			});
			switch (
				courseState.model.course.course.frames[
					courseState.model.course.course.meta.currentframe
				].frametype
			) {
				case "assessment":
					let questionPass = true;
					for (let i in courseState.model.course.course.frames[
						courseState.model.course.course.meta.currentframe
					].assessment) {
						if (i.substring(0, 6) === "answer") {
							if (
								courseState.model.course.course.frames[
									courseState.model.course.course.meta.currentframe
								].assessment[i].eval === false ||
								typeof courseState.model.course.course.frames[
									courseState.model.course.course.meta.currentframe
								].assessment[i].eval === undefined
							) {
								questionPass = false;
							}
						}
					}

					break;
				default:
					break;
			}
			store.dispatch({
				type: "course/setMetaUpload",
				payload: "",
			});
			break;

		case "course/setMetaUpload":
			console.log("Meta Uplpoad middleware");
			break;

		case "course/setFrameStart":
			let theCurrentFrameStart =
				courseState.model.course.course.frames[
					courseState.model.course.course.meta.currentframe
				];
			switch (theCurrentFrameStart.frametype) {
				case "assessment":
					console.log("Setting Question Defaults");
					store.dispatch({
						type: "course/setEvalOnAllAnswers",
					});
					store.dispatch({
						type: "course/deselectAllAnswers",
					});

					break;
				default:
					if (theCurrentFrameStart.audio.url.length > 1) {
						// play audio
						store.dispatch({
							type: "course/hideNavigation",
							payload: true,
						});
						console.log(
							"AUDIO",

							loginState.paths.media + theCurrentFrameStart.audio.url
						);
						var sound = new Howl({
							src: [loginState.paths.media + theCurrentFrameStart.audio.url],
							autoplay: true,
							loop: false,
							volume: 1,
							onend: function () {
								store.dispatch({
									type: "course/hideNavigation",
									payload: false,
								});
							},
						});
					} else {
						store.dispatch({
							type: "course/setAudioPlaying",
							payload: false,
						});
					}

					break;
			}
			break;
		case "course/toggleAnswer":
			let theNumCorrectAnswersPerQuestion = 0;
			const theAnswersToToggle =
				courseState.model.course.course.frames[
					courseState.model.course.course.meta.currentframe
				].assessment;
			for (let i in theAnswersToToggle) {
				if (theAnswersToToggle[i].correct === true) {
					theNumCorrectAnswersPerQuestion++;
				}
			}
			if (theNumCorrectAnswersPerQuestion === 1) {
				store.dispatch({
					type: "course/deselectAllAnswers",
				});
			}
			break;
		case "course/gradeQuestion":
			let numInCorrectAnswers = 0;
			let answerToGrade =
				courseState.model.course.course.frames[
					courseState.model.course.course.meta.currentframe
				].assessment;

			for (let i in answerToGrade) {
				if (i.substr(0, 6) === "answer") {
					if (answerToGrade[i].string !== "") {
						if (answerToGrade[i].correct === answerToGrade[i].selected) {
							store.dispatch({
								type: "course/setAnswerEval",
								payload: { answer: i.substr(6, 1), value: true },
							});
						} else {
							store.dispatch({
								type: "course/setAnswerEval",
								payload: { answer: i.substr(6, 1), value: false },
							});
							numInCorrectAnswers++;
						}
					}
				}
			}
			if (numInCorrectAnswers === 0) {
				action.payload = true;
			} else {
				action.payload = false;
			}

			break;

		case "course/setCourseStatus":
			switch (action.payload) {
				case "STARTED":
					// count number of questions to grade
					store.dispatch({
						type: "course/setCourseTotalGradedQuestions",
						payload: _.filter(
							courseState.model.course.course.frames,
							(frame) => {
								return frame.assessment.question.graded === true;
							},
							true
						).length,
					});
					store.dispatch({
						type: "course/setCourseStartTime",
						payload: "",
					});
					break;
				case "INPROGRESS":
					break;
				case "ENDED":
					let numCorrectAnswers = 0;
					let numWrongAnswers = 0;
					const theTotalNumberOfGradedQuestions = _.filter(
						courseState.model.course.course.frames,
						(frame) => {
							return frame.assessment.question.graded === true;
						},
						true
					);

					for (let i in theTotalNumberOfGradedQuestions) {
						if (
							theTotalNumberOfGradedQuestions[i].assessment.meta.correct ===
							true
						) {
							numCorrectAnswers++;
						} else {
							numWrongAnswers++;
						}
					}
					let percentResults =
						(numCorrectAnswers / (numCorrectAnswers + numWrongAnswers)) * 100;
					store.dispatch({
						type: "course/setResultsCorrect",
						payload: numCorrectAnswers,
					});
					store.dispatch({
						type: "course/setResultsPercent",
						payload: percentResults,
					});
					store.dispatch({
						type: "course/setResultsPassed",
						payload:
							percentResults >=
							Number(courseState.model.course.course.meta.passinggrade)
								? true
								: false,
					});
					break;
				default:
					break;
			}

			break;

		default:
			break;
	}
	return next(action);
};
