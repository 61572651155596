/** @format */

import React, { useState } from "react";
import KeypadButton from "./KeypadButton";
import { useSelector, useDispatch } from "react-redux";
import { login, setLaunchCodeDigit } from "../redux/redux";
import { stylesReducer } from "../redux/stylesReducer";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import beep from "../assets/beep1.mp3";
import cursor from "../assets/cursor_05.gif";
const Keypad = (props) => {
	const dispatch = useDispatch();
	const loginState = useSelector(login);
	const stylesState = useSelector(stylesReducer);
	const activeLoginState = loginState.login.mode.modes.filter((mode) => {
		return mode.code === loginState.login.mode.selected;
	})[0];
	const setButton = (digit) => {
		let audio = new Audio(beep);
		audio.volume = 0.1;
		audio.play();
		if (digit === "X") {
			dispatch(setLaunchCodeDigit(""));
		} else {
			dispatch(setLaunchCodeDigit(activeLoginState.launchcode + digit));
		}
	};
	const setNull = () => {};
	return (
		<>
			<div style={stylesState.keypad.launchCodeKeypadText}>
				<KeypadButton
					digit={activeLoginState.launchcode}
					setButton={setNull}></KeypadButton>
			</div>

			<IonGrid>
				<IonRow>
					<IonCol>
						<KeypadButton digit='1' setButton={setButton}></KeypadButton>
					</IonCol>
					<IonCol>
						<KeypadButton digit='2' setButton={setButton}></KeypadButton>
					</IonCol>
					<IonCol>
						<KeypadButton digit='3' setButton={setButton}></KeypadButton>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<KeypadButton digit='4' setButton={setButton}></KeypadButton>
					</IonCol>
					<IonCol>
						<KeypadButton digit='5' setButton={setButton}></KeypadButton>
					</IonCol>
					<IonCol>
						<KeypadButton digit='6' setButton={setButton}></KeypadButton>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<KeypadButton digit='7' setButton={setButton}></KeypadButton>
					</IonCol>
					<IonCol>
						<KeypadButton digit='8' setButton={setButton}></KeypadButton>
					</IonCol>
					<IonCol>
						<KeypadButton digit='9' setButton={setButton}></KeypadButton>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<KeypadButton digit='X' setButton={setButton}></KeypadButton>
					</IonCol>
					<IonCol>
						<KeypadButton digit='0' setButton={setButton}></KeypadButton>
					</IonCol>
					<IonCol>
						<KeypadButton digit='←' setButton={setButton}></KeypadButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
};
export default Keypad;
