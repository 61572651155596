/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonAlert } from "@ionic/react";
import ReactJson from "react-json-view";
import { login } from "../redux/redux";
import ToggleButton from "../components/ToggleButton";
import { toggleAnswer, gradeQuestion } from "../redux/contentReducer";
import { stylesReducer } from "../redux/stylesReducer";
const ContentFrameAssessment = (props) => {
	const [showSingleAnswerAlert, setShowSingleAnswerAlert] = useState(false);
	const [showMultipleAnswerAlert, setShowMultipleAnswerAlert] = useState(false);
	const dispatch = useDispatch();
	const loginState = useSelector(login);
	const stylesState = useSelector(stylesReducer);
	const setNextButton = () => {
		let numSelectedAnswers = 0;
		if (props.frame.assessment.meta.evaluated === true) {
			props.nextFrame();
		} else {
			for (let answer in props.frame.assessment.answers[
				props.frame.assessment.answers.type
			]) {
				if (
					props.frame.assessment.answers[props.frame.assessment.answers.type][
						answer
					].selected === true
				) {
					numSelectedAnswers++;
				}
			}
			if (
				props.frame.assessment.meta.correctanswers === 1 &&
				numSelectedAnswers === 1
			) {
				dispatch(gradeQuestion());
			} else if (
				props.frame.assessment.meta.correctanswers > 1 &&
				numSelectedAnswers > 1
			) {
				dispatch(gradeQuestion());
			} else {
				if (props.frame.assessment.meta.correctanswers === 1) {
					setShowSingleAnswerAlert(true);
				} else {
					setShowMultipleAnswerAlert(true);
				}
			}
		}
	};
	return (
		<div
			style={{
				display: "flex",
				width: "100vw",
				height: "100vh",
				flexDirection: "column",
			}}>
			<div
				style={{
					color: "goldenrod",
					padding: 10,
					fontWeight: "bold",
					fontSize: "1.25em",
				}}>
				{props.frame.assessment.question.string}
			</div>
			<div
				style={{
					backgroundColor: "lightblue",
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					padding: 0,
					fontWeight: "bold",
				}}>
				{!props.frame.assessment.meta.evaluated ? (
					<>
						{props.frame.assessment.meta.correctanswers === 1 ? (
							<div>Select one answer</div>
						) : (
							<div>Select all that apply</div>
						)}
						{props.frame.assessment.answers[
							props.frame.assessment.answers.type
						].map((answer, index) => (
							<Answer
								number={index}
								key={index}
								model={answer}
								click={(answer) => dispatch(toggleAnswer(answer))}></Answer>
						))}
					</>
				) : (
					<>
						{props.frame.meta.eval ? (
							<div
								style={{
									backgroundColor: "green",
									height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
								}}>
								<div>Passed</div>
								<div>
									{props.frame.assessment.remediation.string
										? props.frame.assessment.remediation.string
										: null}
								</div>
							</div>
						) : (
							<div
								style={{
									backgroundColor: "red",
									height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
								}}>
								<div>Failed</div>
								<div>
									{props.frame.assessment.remediation.string
										? props.frame.assessment.remediation.string
										: null}
								</div>
							</div>
						)}
					</>
				)}
			</div>
			<div
				style={{
					padding: 10,
				}}>
				<ToggleButton
					label='Next'
					click={() => setNextButton()}
					style={loginState.styles.toggleButton}></ToggleButton>
			</div>
			<IonAlert
				isOpen={showSingleAnswerAlert}
				onDidDismiss={() => setShowSingleAnswerAlert(false)}
				header={"Incomplete selection"}
				message={"You must select one answer"}
				buttons={["OK"]}
			/>
			<IonAlert
				isOpen={showMultipleAnswerAlert}
				onDidDismiss={() => setShowMultipleAnswerAlert(false)}
				header={"Incomplete selection"}
				message={"You must select two or more answers"}
				buttons={["OK"]}
			/>
		</div>
	);
};
export default ContentFrameAssessment;

const Answer = (props) => {
	const loginState = useSelector(login);
	return (
		<div>
			<ToggleButton
				label={props.model.string}
				click={props.click}
				selected={props.model.selected}
				value={props.number}
				style={loginState.styles.toggleButton}></ToggleButton>
		</div>
	);
};
