/** @format */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeypadButton from "./KeypadButton";
import moment from "moment";
import { setCourseEnd } from "../redux/contentReducer";
import { Certificate } from "./Certificate";
import { login } from "../redux/redux";
import { contentReducer } from "../redux/contentReducer";

const ContentResults = (props) => {
	const dispatch = useDispatch();
	const contentState = useSelector(contentReducer);
	const loginState = useSelector(login);
	useEffect(() => {
		console.log("course thumbnail", contentState.registration.model.meta);
	}, []);
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}>
			<div>Content Results</div>
			{/* <Certificate
				courseImage={
					loginState.login.paths.media +
					contentState.model.course.thumbnail +
					"-256.jpg"
				}
				studentImage={
					loginState.login.paths.media + contentState.student.photo + "-256.jpg"
				}
				accountImage={
					loginState.login.paths.media +
					contentState.account.thumbnail +
					"-64.jpg"
				}
				date={moment().format("M/D/YYYY")}
				coursecode={contentState.model.course.code}
				coursename={contentState.model.course.name}
				coursedescription={contentState.model.course.description}
				studentname={contentState.student.givenname}
				studentlastname={contentState.student.surname}
				accountname={contentState.account.name}
				score={
					contentState.model.course.course.meta.results.percent
				}></Certificate>*/}
			{
				{
					true: (
						<div>
							<div>Passed</div>
						</div>
					),
					false: (
						<div>
							<div>Failed</div>
						</div>
					),
				}[props.meta.results.passed]
			}
			<div style={{ width: "100%", padding: "2vw" }}>
				<KeypadButton
					digit='Finish'
					setButton={(e) => dispatch(setCourseEnd())}></KeypadButton>
			</div>
		</div>
	);
};
export default ContentResults;
