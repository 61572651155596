/** @format */
const axios = require("axios");
const publicIp = require("public-ip");
export const loginMiddle = (store) => (next) => (action) => {
	switch (action.type) {
		case "login/appInit":
			break;
		case "login/setCourseCodeId":
			let courseidSlice = store.getState().login.course.idlaunch;
			store.dispatch({
				type: "login/setLauchCodeEntryState",
				payload: "LOADING",
			});
			axios({
				method: "get",
				url:
					store.getState().login.paths.api +
					courseidSlice.api +
					action.payload.courseid,
			}).then((res) => {
				res.data.result.course.meta.currentframe = action.payload.frame;

				let course = {};
				course.course = res.data.result;
				store.dispatch({
					type: "course/setCourse",
					payload: course,
				});
				store.dispatch({
					type: "course/setCourseStatus",
					payload: "INPROGRESS",
				});
			});
			break;
		case "login/setLaunchCodeDigit":
			switch (action.payload.substr(action.payload.length - 1)) {
				case "←":
					action.payload = action.payload.substr(0, action.payload.length - 2);
					break;
				default:
					let launchcodeSlice = store
						.getState()
						.login.mode.modes.filter((mode) => {
							return mode.code === store.getState().login.mode.selected;
						})[0];

					if (action.payload.length === 5) {
						store.dispatch({
							type: "login/setLauchCodeEntryState",
							payload: "LOADING",
						});
						store.dispatch({
							type: "content/loadContent",
							payload: action.payload,
						});
						/*
						axios({
							method: "get",
							url:
								store.getState().login.paths.api +
								launchcodeSlice.api +
								action.payload,
						}).then((res) => {
							switch (res.data.status) {
								case "OK":
									
									store.dispatch({
										type: "course/setCourse",
										payload: res.data.result,
									});
									store.dispatch({
										type: "course/setCourseStatus",
										payload: "INPROGRESS",
									});
									store.dispatch({
										type: "course/setStudent",
										payload: res.data.result.student,
									});
									store.dispatch({
										type: "course/setAccount",
										payload: res.data.result.account,
									});

									store.dispatch({
										type: "course/setStudentStatus",
										payload: "LOADED",
									});
									store.dispatch({
										type: "login/setLauchCodeEntryState",
										payload: "LOADED",
									});

									break;
								case "EMPTY":
									store.dispatch({
										type: "login/setStudentStatus",
										payload: "EMPTY",
									});
									store.dispatch({
										type: "login/setCourseStatus",
										payload: "EMPTY",
									});
									store.dispatch({
										type: "login/setLauchCodeEntryState",
										payload: "EMPTY",
									});
									store.dispatch({
										type: "login/resetLogin",
										payload: "EMPTY",
									});

									break;
								default:
									console.log("Status not set");
							}
						})*/
					}
			}

			break;

		default:
			break;
	}
	return next(action);
};
