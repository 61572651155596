/** @format */

import React from "react";
import { IonButton } from "@ionic/react";
import { useSelector } from "react-redux";
import { stylesReducer } from "../redux/stylesReducer";
import { courseReducer } from "../redux/courseReducer";
import Next from "../assets/Next.png";
import Previous from "../assets/Previous.png";
import click from "../assets/click.png";
import Loop from "../assets/Loop.png";
const Navigation = (props) => {
	const stylesState = useSelector(stylesReducer);
	const courseState = useSelector(courseReducer);
	return (
		<div style={stylesState.navigation.container}>
			{!props.hidePrevious ? (
				<div
					style={stylesState.navigation.button}
					onClick={(e) => {
						props.click("PREVIOUS");
					}}>
					<img
						src={Previous}
						alt='Previous'
						style={stylesState.navigation.icon}></img>
				</div>
			) : null}

			<div
				style={stylesState.navigation.button}
				onClick={(e) => {
					props.click("REPEAT");
				}}>
				<img src={Loop} alt='Loop' style={stylesState.navigation.icon}></img>
			</div>

			{!props.hideNext ? (
				<div
					style={stylesState.navigation.button}
					onClick={(e) => {
						props.click("NEXT");
					}}>
					<img src={Next} alt='Next' style={stylesState.navigation.icon}></img>
				</div>
			) : null}
		</div>
	);
};
export default Navigation;
