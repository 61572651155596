/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { stylesReducer } from "../redux/stylesReducer";
import {
	IonContent,
	IonPage,
	IonHeader,
	IonFooter,
	IonTitle,
	IonToolbar,
	IonSlides,
	IonSlide,
	IonProgressBar,
} from "@ionic/react";
import ContentFrame from "./ContentFrame";
import ContentResults from "./ContentResults";
import Navigation from "./Navigation";
import {
	contentReducer,
	frames,
	currentframe,
	setCurrentFrame,
	imageSuffix,
	videoSuffix,
	narrationSuffix,
	mediaPrefix,
	meta,
} from "../redux/contentReducer";
const Content = () => {
	const currentframeState = useSelector(currentframe);
	const contentState = useSelector(contentReducer);
	const imageSuffixState = useSelector(imageSuffix);
	const videoSuffixState = useSelector(videoSuffix);
	const narrationSuffixState = useSelector(narrationSuffix);
	const mediaPrefixState = useSelector(mediaPrefix);
	const framesState = useSelector(frames);
	const metaState = useSelector(meta);
	const dispatch = useDispatch();
	const contentSlides = useRef(null);
	const stylesState = useSelector(stylesReducer);
	const [replayIndex, setReplayIndex] = useState(false);
	const [mediaPosition, setMediaPosition] = useState(0);
	const [mediaState, setMediaState] = useState("IDLE");
	const navigationButton = (button) => {
		/*
		contentSlides.current.getActiveIndex().then((index) => {
			dispatch(setCurrentFrame(index));
		});*/
		switch (button) {
			case "NEXT":
				contentSlides.current.slideNext();
				break;
			case "PREVIOUS":
				contentSlides.current.slidePrev();

				break;
			case "REPEAT":
				setMediaState("PLAY");
				break;
			default:
				break;
		}
	};

	const slideOpts = {
		initialSlide: metaState.currentframe,
		preloadImages: true,
		autoHeight: false,
		on: {
			slideChange() {
				//console.log("Slide change");
				contentSlides.current.getActiveIndex().then((frameIndex) => {
					dispatch(setCurrentFrame(frameIndex));
					setMediaState("PLAY");
				});
			},
			click() {
				setMediaState("PLAY");
			},
			progress(ev) {
				//console.log("progress", ev);
			},
		},
	};
	useEffect(() => {
		setMediaState("PLAY");
	}, [dispatch]);
	useEffect(() => {
		switch (mediaState) {
			case "PLAY":
				contentSlides.current.lockSwipeToNext(true);
				contentSlides.current.lockSwipeToPrev(true);
				break;
			case "IDLE":
				contentSlides.current.lockSwipeToNext(false);
				contentSlides.current.lockSwipeToPrev(false);
				break;
			default:
				break;
		}
	}, [mediaState]);

	return (
		<IonPage>
			<IonHeader>
				{currentframeState ? (
					currentframeState.frametype === "video" && mediaState === "IDLE" ? (
						<IonToolbar>
							<IonTitle>{currentframeState.title.string}</IonTitle>
						</IonToolbar>
					) : null
				) : null}
			</IonHeader>
			<IonContent>
				<IonSlides
					ref={contentSlides}
					pager={false}
					options={slideOpts}
					style={{ height: "100%" }}>
					{framesState.map((frame, index) => (
						<IonSlide key={frame.id}>
							<ContentFrame
								meta={metaState}
								frameNumber={index}
								currentFrameIndex={
									contentState.registration.model.meta.currentframe
								}
								mediaState={mediaState}
								replayIndex={replayIndex}
								setReplayIndex={setReplayIndex}
								playing={(ev) => setMediaPosition(ev)}
								frame={frame}
								style={stylesState}
								setMediaState={(ev) => setMediaState(ev)}
								imageSuffix={imageSuffixState}
								videoSuffix={videoSuffixState}
								narrationSuffix={narrationSuffixState}
								mediaPrefix={mediaPrefixState}
								nextFrame={(ev) => navigationButton("NEXT")}></ContentFrame>
						</IonSlide>
					))}
					<IonSlide>
						<ContentResults meta={metaState}></ContentResults>
					</IonSlide>
				</IonSlides>
			</IonContent>
			{currentframeState &&
			currentframeState.frametype !== "assessment" &&
			metaState.status !== "FINISHED" ? (
				<IonFooter
					style={{
						height:
							currentframeState.video.url.length > 1 && mediaPosition ? 5 : 50,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}>
					{mediaState === "IDLE" ? (
						<Navigation
							hidePrevious={
								contentState.registration.model.meta.currentframe === 0
							}
							hideNext={false}
							click={navigationButton}></Navigation>
					) : (
						<div>
							{currentframeState.audio.url.length > 1 ? (
								<div
									style={{
										fontSize: "xx-small",
										color: "black",
										textAlign: "center",
									}}>
									{currentframeState.audio.string}
								</div>
							) : null}

							<IonProgressBar
								value={mediaPosition}
								buffer={0}
								mode='ios'></IonProgressBar>
						</div>
					)}
				</IonFooter>
			) : null}
		</IonPage>
	);
};
export default Content;
