/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import ReactJson from "react-json-view";

const ContentFrameImagetext = (props) => {
	const audioPlayer = useRef(null);
	const [progress, setProgress] = useState(0);
	useEffect(() => {
		if (
			props.frameNumber === props.currentFrameIndex &&
			props.meta.status !== "FINISHED"
		) {
			switch (props.mediaState) {
				case "PLAY":
					if (props.frame.audio.url.length > 1) {
						audioPlayer.current.currentTime = 0;
						try {
							audioPlayer.current.play();
						} catch (err) {
							console.log("Error playing file");
						}
					}
					break;
				default:
					break;
			}
		}
	}, [props]);

	const playing = () => {
		setProgress(audioPlayer.current.currentTime / audioPlayer.current.duration);
		if (audioPlayer.current.ended === true) {
			props.mediaplaying(0);
			props.setPlayMedia(false);
			props.setMediaState("IDLE");
		} else {
			props.mediaplaying(progress);
			props.setMediaState("PLAYING");
		}
	};

	return (
		<>
			<IonGrid>
				<IonRow>
					{props.frame.text.string.length > 1 ? (
						<IonCol
							size-lg={props.frame.image.url.length > 1 ? "3" : "12"}
							size-md={props.frame.image.url.length > 1 ? "4" : "12"}
							size-sm={props.frame.image.url.length > 1 ? "6" : "12"}
							size={props.frame.image.url.length > 1 ? "12" : "12"}
							style={props.style.left}>
							<div>{props.frame.text.string}</div>
						</IonCol>
					) : null}

					{props.frame.image.url.length > 1 ? (
						<IonCol
							size-lg={props.frame.text.string.length > 1 ? "3" : "12"}
							size-md={props.frame.text.string.length > 1 ? "4" : "12"}
							size-sm={props.frame.text.string.length > 1 ? "6" : "12"}
							size={props.frame.text.string.length > 1 ? "12" : "12"}
							style={props.style.right}>
							<div>
								<img
									src={props.image}
									alt={props.frame.title.string}
									style={{
										height:
											props.frame.image.height >= props.frame.image.width
												? "100vh"
												: null,
										width:
											props.frame.image.width > props.frame.image.height
												? "100vw"
												: null,
									}}></img>
							</div>
						</IonCol>
					) : null}
				</IonRow>
			</IonGrid>
			{props.frame.audio.url.length > 1 ? (
				<audio
					onTimeUpdate={playing}
					src={props.narration}
					ref={audioPlayer}
					loop={false}
					style={{ visibility: false }}>
					Your browser does not support the
					<code>audio</code> element.
				</audio>
			) : null}
		</>
	);
};
export default ContentFrameImagetext;
