/** @format */

import { createSlice } from "@reduxjs/toolkit";

export const stylesSlice = createSlice({
	name: "styles",
	initialState: {
		general: {
			center: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			fullscreen: {
				width: "100vw",
				height: "100vh",
			},
			fullwidth: {
				width: "100%",
				height: "100%",
			},
			vertical: {
				flexDirection: "column",
			},
			horizontal: {
				flexDirection: "row",
			},
			spaceBetween: {
				justifyContent: "space-between",
			},
		},
		login: {
			container: {
				width: "100vw",
				height: "100vh",
				padding: "5vh",
			},
			logoContainer: {
				padding: "1vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			},
			logo: { height: "10vh" },
		},
		languages: {
			panel: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
			},
		},
		keypad: {
			input: {
				fontSize: "20vw",
				textAlign: "center",
			},
			cursor: {
				height: "11vw",
			},
			launchCodeKeypadText: {
				fontSize: "15vw",
				borderColor: "white",
				borderStyle: "solid",
				borderWidth: 0,
				width: "100%",
				color: "black",
				textAlign: "center",
				fontWeight: "bold",
			},
			keypadButton: {
				width: "100%",
				height: "100%",
				backgroundColor: "#637694",
				borderRadius: "2vw",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				borderColor: "white",
				borderWidth: 3,
				borderStyle: "solid",
				margin: ".75vw",
				color: "white",
				fontWeight: "bold",
				fontSize: "10vh",
			},
		},
		frame: {
			container: {
				display: "flex",
				flexDirection: "row",
				width: "100vw",
				height: "100%",
			},
			left: {
				textAlign: "center",
				justifyContent: "center",
				alignItems: "center",
				display: "flex",
				width: "100%",
				height: "100%",
				padding: "4vw",
				color: "goldenrod",
				fontSize: "1.5em",
				fontWeight: "bold",
			},
			right: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
			},
			video: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "black",
			},
		},

		assessment: {
			container: {
				display: "flex",
				flexDirection: "column",
				width: "100vw",
				height: "75vh",
			},
			answers: {},
		},
		remediation: {
			container: {
				display: "flex",
				flexDirection: "column",
				width: "100vw",
				height: "75vh",
				alignItems: "center",
				justifyContent: "center",
				fontSize: "5vh",
				fontWeight: "bold",
				textAlign: "center",
				padding: "3vh",
			},
			correct: {
				backgroundColor: "green",
				opacity: 0.75,
			},
			incorrect: {
				backgroundColor: "red",
				opacity: 0.75,
			},
			image: {
				height: "20vh",
				opacity: 0.5,
				margin: "5vh",
			},
		},

		toggleButton: {
			button: {
				borderWidth: "2px",
				borderStyle: "solid",
				borderColor: "#637694",
				padding: 5,
				textAlign: "center",
				textTransform: "uppercase",
				textWeight: "bold",
				color: "black",
				margin: "1vh",
				fontSize: "4vh",
			},
			buttonSelected: {
				backgroundColor: "goldenrod",
				fontWeight: "bold",
				color: "white",
			},
		},
		navigation: {
			container: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				width: "100%",
				height: "100%",
				alignItems: "center",
			},
			button: {
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100%",
				color: "white",
				fontWeight: "bold",
				opacity: 0.5,
				backgroundColor: "#637694",
				borderColor: "lightgrey",
				borderStyle: "solid",
				borderWidth: 3,
				textTransform: "uppercase",
			},
			icon: {
				height: "5vh",
			},
			message: {
				mode: "ClickOnVideo",
				container: {
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					width: "100%",
					height: "15vh",
					alignItems: "center",
				},
				none: {
					message: "No message",
					icon: "",
				},
				ClickOnVideo: {
					message: "Click on video to start",
					icon: "click.png",
				},
			},
		},
		header: {
			container: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				width: "100vw",
				height: "10vh",
				alignItems: "center",
				textAlign: "center",
				fontWeight: "bold",
				fontSize: "3.5vw",
				textTransform: "uppercase",
				color: "black",
				opacity: 0.4,
			},
		},
		results: {
			container: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				width: "100vw",
				height: "100vh",
				alignItems: "center",
				textAlign: "center",
				textTransform: "uppercase",
				color: "black",
				opacity: 0.8,
			},
			pass: {
				backgroundColor: "green",
			},
			fail: {
				backgroundColor: "#e0e9e4",
			},
		},
	},

	reducers: {
		initStyles: (state) => {
			console.log("Init styles");
		},
		setNavigationMessage: (state, action) => {
			state.navigation.message.mode = action.payload;
		},
	},
});

export const { initStyles, setNavigationMessage } = stylesSlice.actions;
export const stylesReducer = (state) => state.styles;
export default stylesSlice.reducer;
