/** @format */

import { createSlice } from "@reduxjs/toolkit";

export const courseSlice = createSlice({
	name: "course",
	initialState: {
		paths: {
			api: "https://cynzexsnd5.execute-api.us-west-2.amazonaws.com/dev/",
			media: "",
		},
		hideNavigation: false,
		model: {},
	},
	reducers: {
		initCourse: (state) => {},
		setCourse: (state, action) => {
			state.model = action.payload;
		},
		setNextCourseFrame: (state, action) => {
			state.model.course.course.meta.currentframe++;
			let theAssessmentFrame =
				state.model.course.course.frames[
					state.model.course.course.meta.currentframe
				].assessment;
			theAssessmentFrame.answer1.selected = false;
			theAssessmentFrame.answer2.selected = false;
			theAssessmentFrame.answer3.selected = false;
			theAssessmentFrame.answer4.selected = false;
			theAssessmentFrame.answer5.selected = false;
			theAssessmentFrame.answer1.eval = false;
			theAssessmentFrame.answer2.eval = false;
			theAssessmentFrame.answer3.eval = false;
			theAssessmentFrame.answer4.eval = false;
			theAssessmentFrame.answer5.eval = false;
		},
		setPreviousCourseFrame: (state, action) => {
			console.log("Previous frame");
			state.model.course.course.meta.currentframe--;
		},
		setCourseStatus: (state, action) => {
			state.model.course.course.meta.status = action.payload;
		},

		setFrame: (state, action) => {
			state.model.course.course.meta.currentframe = action.payload;
		},

		setStudent: (state, action) => {
			state.student = action.payload;
		},
		setAccount: (state, action) => {
			state.account = action.payload;
		},
		setStudentStatus: (state, action) => {
			state.student.status = action.payload;
		},
		setCourseTotalGradedQuestions: (state, action) => {
			state.model.course.course.meta.results.total = action.payload;
		},
		setCourseStartTime: (state) => {
			state.model.course.course.meta.results.start = Date.now();
		},
		setCourseEndTime: (state) => {
			state.model.course.course.meta.results.end = Date.now();
		},
		toggleAnswer: (state, action) => {
			state.model.course.course.frames[
				state.model.course.course.meta.currentframe
			].assessment["answer" + action.payload].selected = state.model.course
				.course.frames[state.model.course.course.meta.currentframe].assessment[
				"answer" + action.payload
			].selected
				? false
				: true;
		},
		hideNavigation: (state, action) => {
			state.model.course.hideNavigation = action.payload;
		},
		setEvalOnAllAnswers: (state, action) => {
			let theAssessmentFrame =
				state.model.course.course.frames[
					state.model.course.course.meta.currentframe
				].assessment;
			theAssessmentFrame.answer1.eval = false;
			theAssessmentFrame.answer2.eval = false;
			theAssessmentFrame.answer3.eval = false;
			theAssessmentFrame.answer4.eval = false;
			theAssessmentFrame.answer5.eval = false;
		},
		deselectAllAnswers: (state) => {
			let theAssessmentFrame =
				state.model.course.course.frames[
					state.model.course.course.meta.currentframe
				].assessment;
			theAssessmentFrame.answer1.selected = false;
			theAssessmentFrame.answer2.selected = false;
			theAssessmentFrame.answer3.selected = false;
			theAssessmentFrame.answer4.selected = false;
			theAssessmentFrame.answer5.selected = false;
		},
		setAnswerEval: (state, action) => {
			state.model.course.course.frames[
				state.model.course.course.meta.currentframe
			].assessment["answer" + action.payload.answer].eval =
				action.payload.value;
		},
		gradeQuestion: (state, action) => {
			console.log("ACTION", action);
			state.model.course.course.frames[
				state.model.course.course.meta.currentframe
			].assessment.meta.graded = true;
			state.model.course.course.frames[
				state.model.course.course.meta.currentframe
			].assessment.meta.correct = action.payload;
		},
		deselectAnswer: (state, action) => {
			state.model.course.course.frames[
				state.model.course.course.meta.currentframe
			].assessment["answer" + action.payload].selected = false;
		},

		setFrameStart: (state) => {
			state.model.course.course.frames[
				state.model.course.course.meta.currentframe
			].meta.start = Date.now();
		},

		setMetaUpload: (state) => {
			console.log("Meta Upload");
		},

		setFrameEndTime: (state) => {
			state.model.course.course.frames[
				state.model.course.course.meta.currentframe
			].meta.end = Date.now();
		},

		setResultsCorrect: (state, action) => {
			state.model.course.course.meta.results.correct = action.payload;
		},
		setResultsPassed: (state, action) => {
			state.model.course.course.meta.results.passed = action.payload;
		},
		setResultsPercent: (state, action) => {
			state.model.course.course.meta.results.percent = action.payload;
		},
		removeCourse: (state, action) => {
			state.model = {};
		},
	},
});

export const {
	initCourse,
	setCourse,
	setNextCourseFrame,
	setPreviousCourseFrame,
	setFrame,
	setCourseStatus,
	setCourseTotalGradedQuestions,
	setStudent,
	setAccount,
	setStudentStatus,
	setCourseStartTime,
	setCourseEndTime,
	setFrameStart,
	setFrameEndTime,
	toggleAnswer,
	setEvalOnAllAnswers,
	deselectAnswer,
	deselectAllAnswers,
	setAnswerEvaluation,
	hideNavigation,
	removeCourse,
	gradeQuestion,
} = courseSlice.actions;
export const courseReducer = (state) => state.course;
export default courseSlice.reducer;
