/** @format */

import { createSlice } from "@reduxjs/toolkit";

export const contentSlice = createSlice({
	name: "content",
	initialState: {
		paths: {
			api: "https://cynzexsnd5.execute-api.us-west-2.amazonaws.com/dev/",
			media: "https://d3refhg816sd0y.cloudfront.net/public/media/out/",
		},
		api: {
			loadCourse: "getCourseFromRegistrationLaunchCode2?launchcode=",
			setRegistration: "setRegistration",
		},
		registration: {},
	},
	reducers: {
		initContent: (state, action) => {},
		loadContent: (state, action) => {},

		setModel: (state, action) => {
			state.registration = action.payload;
		},
		setCourseStatus: (state, action) => {
			state.registration.model.meta.status = action.payload;
		},
		setStartTime: (state, action) => {
			state.registration.model.meta.results.start = Date.now();
		},
		initModel: (state, action) => {
			let theFrames = state.registration.model.frames;
			for (let frame in theFrames) {
				if (
					theFrames[frame].frametype === "assessment" &&
					theFrames[frame].assessment.meta.grade === true
				) {
					state.registration.model.meta.results.total++;
				}
			}
		},
		uploadCourse: () => {},

		setCurrentFrame: () => {},
		setCurrentFrameNum: (state, action) => {
			console.log("setCurrentFrameNum", action);
			state.registration.model.meta.currentframe = Number(action.payload);
			/*
			state.registration.model.frames[
				state.registration.model.meta.currentframe - 1
			].meta.end = Date.now();
			*/
			state.registration.model.frames[
				state.registration.model.meta.currentframe
			].meta.start = Date.now();
		},
		toggleAnswer: (state, action) => {
			const theFrames = state.registration.model.frames;
			const theCurrentFrameIndex = state.registration.model.meta.currentframe;
			const theCurrentFrame = theFrames[theCurrentFrameIndex];
			const theCurrentAnswers =
				theCurrentFrame.assessment.answers[
					theCurrentFrame.assessment.answers.type
				];
			theCurrentAnswers[action.payload].selected = !theCurrentAnswers[
				action.payload
			].selected;
		},
		deselectAllAnswers: (state, action) => {
			const theFrames = state.registration.model.frames;
			const theCurrentFrameIndex = state.registration.model.meta.currentframe;
			const theCurrentFrame = theFrames[theCurrentFrameIndex];
			const theCurrentAnswers =
				theCurrentFrame.assessment.answers[
					theCurrentFrame.assessment.answers.type
				];
			for (let answer in theCurrentAnswers) {
				theCurrentAnswers[answer].selected = false;
			}
		},
		gradeQuestion: (state, action) => {
			let wrongAnswers = 0;
			const theFrames = state.registration.model.frames;
			const theCurrentFrameIndex = state.registration.model.meta.currentframe;
			const theCurrentFrame = theFrames[theCurrentFrameIndex];
			const theCurrentAnswers =
				theCurrentFrame.assessment.answers[
					theCurrentFrame.assessment.answers.type
				];
			theCurrentAnswers.forEach((answer) => {
				if (answer.selected !== answer.correct) {
					answer.eval = false;
					wrongAnswers++;
				} else {
					answer.eval = true;
				}
			});
			theCurrentFrame.meta.eval = wrongAnswers > 0 ? false : true;
			theCurrentFrame.assessment.meta.evaluated = true;
			if (theCurrentFrame.assessment.meta.grade === true) {
				if (theCurrentFrame.meta.eval === true) {
					state.registration.model.meta.results.correct++;
				}
			}
			state.registration.model.meta.results.percent = Math.round(
				(state.registration.model.meta.results.correct /
					state.registration.model.meta.results.total) *
					100
			);
			state.registration.model.meta.results.passed =
				state.registration.model.meta.results.percent >=
				state.registration.model.meta.passinggrade
					? true
					: false;
		},
		gradeCourse: (state) => {
			console.log("Grading Course");
		},
		addCorrectAnswer: (state, action) => {
			state.registration.model.meta.results.correct =
				state.registration.model.meta.results.correct + action.payload;
		},
		setCourseEnd: (state) => {
			state.registration = {};
		},
	},
});

export const {
	initContent,
	setCurrentFrame,
	toggleAnswer,
	gradeQuestion,
	setCourseEnd,
} = contentSlice.actions;
export const contentReducer = (state) => state.content;
export const meta = (state) => state.content.registration.model.meta;

export const frames = (state) => state.content.registration.model.frames;
export const currentframe = (state) =>
	state.content.registration.model.frames[
		state.content.registration.model.meta.currentframe
	];
export const mediaPrefix = (state) => state.content.paths.media;
export const imageSuffix = (state) =>
	state.content.registration.model.meta.compressions.images.compressions[
		state.content.registration.model.meta.compressions.images.default
	].suffix;
export const videoSuffix = (state) =>
	state.content.registration.model.meta.compressions.video.compressions[
		state.content.registration.model.meta.compressions.video.default
	].suffix;
export const narrationSuffix = (state) =>
	state.content.registration.model.meta.compressions.narration.compressions[
		state.content.registration.model.meta.compressions.narration.default
	].suffix;
export default contentSlice.reducer;
