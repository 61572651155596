/** @format */

import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import {
	IonApp,
	IonIcon,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { rocket, square, triangle } from "ionicons/icons";
import Pair from "./pages/Pair";
import Code from "./pages/Code.jsx";
import Login from "./pages/Login";
import Main from "./pages/Main";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { setLaunchCodeDigit, setCourseCodeId } from "./redux/redux";
import { initContent } from "./redux/contentReducer";
// import { setFrame } from "./redux/courseReducer";

import { useSelector, useDispatch } from "react-redux";

const App = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		let launchCode = getQueryVariable("launchcode");
		//launchCode = "81126";
		if (launchCode) {
			if (launchCode.length === 5) {
				dispatch(setLaunchCodeDigit(launchCode));
			}
		}
		const courseid = getQueryVariable("courseid");
		const frame = getQueryVariable("frame");
		if (courseid) {
			dispatch(setCourseCodeId({ courseid: courseid, frame: frame }));
		}

		dispatch(initContent({ courseid: courseid, frame: frame }));
	}, [dispatch]);
	function getQueryVariable(variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] === variable) {
				return pair[1];
			}
		}
		return false;
	}

	return (
		<IonApp>
			<IonReactRouter>
				{/* <IonTabs> */}
				<IonRouterOutlet>
					<Route path='/viar' component={Main} exact={true} />
					{/* <Route path='/pair' component={Pair} exact={true} />
				<Route path='/code' component={Code} exact={true} />
<Route path='/login' component={Login} exact={true} /> */}
					<Route path='/' render={() => <Redirect to='/viar' />} exact={true} />
					<Route
						path='/index.html'
						render={() => <Redirect to='/viar' />}
						exact={true}
					/>
				</IonRouterOutlet>
				{/* <IonTabBar slot='bottom'>
					<IonTabButton tab='code' href='/code'>
						<IonIcon icon={rocket} />
						<IonLabel>Launch Code</IonLabel>
					</IonTabButton>
</IonTabBar> */}
				{/* </IonTabs> */}
			</IonReactRouter>
		</IonApp>
	);
};

export default App;
