/** @format */

import { createSlice } from "@reduxjs/toolkit";

export const frameSlice = createSlice({
	name: "frame",
	initialState: {
		frame: {},
	},
	reducers: {
		initFrame: (state, action) => {
			console.log("Initializing Frame");
		},
	},
});

export const { initFrame } = frameSlice.actions;
export const frameReducer = (state) => state.frame;
export default frameSlice.reducer;
