/** @format */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { stylesReducer } from "../redux/stylesReducer";

const KeypadButton = (props) => {
	const stylesState = useSelector(stylesReducer);
	return (
		<div
			onClick={(e) => props.setButton(props.digit)}
			style={stylesState.keypad.keypadButton}>
			{props.digit}
		</div>
	);
};
export default KeypadButton;
