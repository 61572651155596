/** @format */

import { createSlice } from "@reduxjs/toolkit";
import publicIP from "react-native-public-ip";

export const loginSlice = createSlice({
	name: "login",
	initialState: {
		ip: "",
		paths: {
			api: "https://cynzexsnd5.execute-api.us-west-2.amazonaws.com/dev/",
			media: "https://d3refhg816sd0y.cloudfront.net/public/media/out/",
		},

		lang: {
			language: "en",

			languages: [
				{
					name: "Español",
					code: "es",
				},
				{
					name: "Français",
					code: "fr",
				},
				{
					name: "English",
					code: "en",
				},
				{
					name: "Deutsche",
					code: "de",
				},
				{
					name: "Portugês",
					code: "pt",
				},
			],
		},
		mode: {
			selected: "LAUNCHCODE",
			styles: {
				panel: {
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				},
			},
			modes: [
				{
					code: "LAUNCHCODE",
					name: {
						en: "Launch Code",
						es: "Codigo de Arranque",
						fr: "Code de lancement",
						de: "Startcode",
						pt: "Código de lançamento",
					},
					launchcode: "",
					api: "getCourseFromRegistrationLaunchCode?launchcode=",
					mock: "14067",
					status: "IDLE",
				},
			],
		},
		course: {
			status: "IDLE",
			model: {},
			idlaunch: {
				code: "COURSEID",
				coursecode: "",
				api: "getCourseFromCourseId?id=",
				status: "IDLE",
			},
		},
		student: {
			status: "IDLE",
			model: {},
		},
	},
	reducers: {
		appInit: (state, payload) => {
			state.ip = payload;
		},
		setLaunchCodeDigit: (state, action) => {
			let theLaunchCode = state.mode.modes.filter((mode) => {
				return mode.code === state.mode.selected;
			})[0];

			theLaunchCode.launchcode = action.payload;
		},

		setLauchCodeEntryState: (state, action) => {
			let theLaunchCode = state.mode.modes.filter((mode) => {
				return mode.code === state.mode.selected;
			})[0];
			theLaunchCode.status = action.payload;
		},
		setLanguage: (state, action) => {
			state.lang.language = action.payload;
		},
		setMode: (state, action) => {
			state.mode.selected = action.payload;
		},
		resetLogin: (state) => {
			let theLaunchCode = state.mode.modes.filter((mode) => {
				return mode.code === state.mode.selected;
			})[0];
			theLaunchCode.launchcode = "";
		},
		setCourseCodeId: (state, payload) => {
			console.log("Setting Course Code ID", payload);
		},
	},
});

export const {
	appInit,
	setLaunchCodeDigit,
	setCourseCodeId,
	setLauchCodeEntryState,
	setLanguage,
	setMode,
	resetLogin,
} = loginSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
	setTimeout(() => {
		//
	}, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const login = (state) => state;
//export const stylesState = (state) => state.styles;
export default loginSlice.reducer;
