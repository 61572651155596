/** @format */
import React from "react";
const ToggleButton = (props) => {
	return (
		<div
			onClick={(e) => props.click(props.value)}
			style={{
				...props.style.button,
				...(props.selected ? props.style.buttonSelected : null),
			}}>
			{props.label}
		</div>
	);
};
export default ToggleButton;
